/* You can add global styles to this file, and also import other style files */
// @import url('https://use.typekit.net/uno4xlv.css'); // Typekit font Canada Type Gibson
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css');

.rc-anchor-error-msg-container {
    color: #8b0000 !important;
}

.recaptcha-checkbox-expired .recaptcha-checkbox-border,
.recaptcha-checkbox-expired .recaptcha-checkbox-spinner-gif {
    border: 2px solid #8b0000 !important;
}

.producer-logon-application {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex-grow: 1;
    background: #e1e4e9;    
    padding: 0;
}

@media (min-width: 768px) {
    main {
        padding: 40px 0 72px;
    }
}

html,
body,
p {
    font-family:
        'IBM Plex Sans',
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        sans-serif;
    color: #484848;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.22px;
}

/*** 
**** Application Header branding
***/

.app-brand {
    flex: 1;
}

.app-brand .logo {
    width: auto;
    height: 60px;
}

.app-header {
    border-bottom: 2px solid #007b87;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding: 0 16px;
    align-items: center;
    flex-direction: column;
}

@media (min-width: 768px) {
    .app-header {
        height: 72px;
        flex-direction: row;
    }
}

.app-header .navbar-nav {
    display: flex;
    flex-direction: row;
    margin: 16px 0;
}

@media (min-width: 768px) {
    .app-header .navbar-nav {
        flex-direction: row;
    }
}

.app-header .navbar-nav .nav-item {
    color: #007b87;
}

.app-header .app-brand a:hover {
    text-decoration: none;
    border-bottom: none;
}

.app-header .navbar-nav .nav-link {
    font-weight: 600;
    font-size: inherit;
    padding: 0;
    margin: 0 16px;
    display: inline-block;
}

.app-header .navbar-nav .nav-link:hover {
    color: #484848;
    text-decoration: none;
    border-bottom: 1px solid #007b87;
    cursor: pointer;
}

.app-header .navbar-nav .nav-item .bi-telephone-fill {
    margin-right: -10px;
}

/*****************************************************/

a {
    color: #007b87;
    font-weight: 400;
    font-size: inherit;
    text-decoration: none;
}

a:active {
    color: #027b87;
}

a:hover,
a[href^='tel']:hover,
a[href^='mailto']:hover {
    color: #484848;
    text-decoration: none;
    border-bottom: 1px solid #007b87;
}

i.bi {
    color: #007b87;
}

.lead {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #d8d8d8;
    font-size: 20px;
    line-height: 1.3;    
    font-weight: 500;
}

.alert {
    display: block;    
}

.alert-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.alert .alert-heading {
    font-family: canada-type-gibson, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-top: 4px;
}

.alert .bi {
    font-size: 32px;
    margin-right: 16px;
}

.alert.alert-success .alert-heading,
.alert.alert-success .alert-message,
.alert.alert-success .bi {
   color: #0a3622;
}

.alert.alert-danger .alert-heading,
.alert.alert-danger .alert-message,
.alert.alert-danger .bi {
    color: #721c24;
}

.action-link a {
    font-weight: 600;
}

.router-link {
    color: #007b87;
    font-weight: 400;
    font-size: inherit;
    text-decoration: none;
}

.router-link:hover {
    color: #484848;
    text-decoration: none;
    border-bottom: 1px solid #007b87;
    cursor: pointer;
}

.footer .nav-link {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    padding: 0;
    display: inline;
}

.footer .nav-link:hover {
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid #ffffff;
}

.button-row {
    border-bottom: 1px #bae1e3 solid;
    padding: 2rem 0;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button-row.no-separator,
.button-row.borderless {
    border-bottom: none;
}

.help-row {
    margin-top: 24px;
    text-align: center;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group.password-group {
    position: relative;
}

.form-group.password-group .caps-lock-warning {
    color: #8b0000;
    font-weight: 600;
}

.form-group .toggle-password {
    position: absolute;
    right: 10px;
    top: 40px;
    font-size: 1.3rem;
    cursor: pointer;
}

.form-group .toggle-password.interior-form {
    top: 34px;
}

//  This might not be needed - recheck during mobile testing
.card {
    border: none;
    border-radius: 0;
    padding: 32px 16px;
    background-color: #fff;
    margin: 0 auto;
    box-shadow:
        2px 40px 34px -20px rgba(29, 42, 60, 0.14),
        6px 10px 16px 0 rgba(29, 42, 60, 0.04);
}
@media (min-width: 768px) {
    .card {
        // border: 1px solid #d8d8d8; // #a2a3a5;
        border-radius: 0.375rem;
        padding: 32px 48px;
    }
}

.form-control {
    color: #000000;
    border: 1px solid #a2a3a5;
    border-radius: 4px;
    padding: 10px 16px;
    display: block;
    height: auto;
}

.form-control:focus {
    box-shadow: 0 0 0 1px #007b87;
    border-color: #007b87;
    outline: 0;
}

.form-control.ng-invalid.ng-touched {
    box-shadow: 0 0 0 1px #8b0000;
    border-color: #8b0000;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('../src/assets/icons/exclamation-circle.svg');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    outline: 0;
}

.form-control.password-control.ng-invalid.ng-touched {
    background-position: right calc(2.5em + 0.1875rem) center;
}

.form-group.form-check {
    padding-left: 0;
}

.form-group.form-check input[type='checkbox'] {
    opacity: 0;
}

.form-group.form-check label {
    position: relative;
    display: block;
    padding-left: 32px;
}

.form-group.form-check label::before,
.form-group.form-check label::after {
    position: absolute;
    content: '';
    display: inline-block;
}

.form-group.form-check label::before {
    height: 24px;
    width: 24px;
    border: 1px solid #a2a3a5;
    left: 0;
    top: 0;
    border-radius: 4px;
}

.form-group.form-check.use-email label::before {
    height: 24px;
    width: 24px;
}

.form-group.form-check:hover label::before {
    border: 1px solid #007b87;
    background-color: #f2fbfc;
}

.form-group.form-check label::after {
    height: 7px;
    width: 14px;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 6px;
    top: 6px;
}

.form-group .has-input-icon {
    padding-left: 45px;
}

.form-group .input-icon {
    float: left;
    position: relative;
    margin-top: -36px;
    margin-left: 16px;
    z-index: 2;
    color: #007b87;
    font-size: 1.2rem;
}

.tip {
    display: inline-block;
}

.bi-telephone-fill::before {
    transform: rotate(30deg);
}

/* Hide the checkmark by default*/
.form-group.form-check input[type='checkbox'] + label::after {
    content: none;
}

/* Unhide the checkmark on the checked state*/
.form-group.form-check input[type='checkbox']:checked + label::after {
    content: '';
    color: #ffffff;
}

.form-group.form-check input[type='checkbox']:checked + label::before {
    background-color: #007b87;
    border: 1px solid #007b87;
}

.nmu .form-group.form-check input[type='checkbox']:checked + label::before {
    background-color: #133d8d;
    border: 1px solid #133d8d;
}

label {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 0;
}

.btn {
    position: relative;
    display: inline-block;
    justify-content: center;
    border-radius: 4px;
    padding: 0.5rem 2rem;
    font-family:
        canada-type-gibson,
        'IBM Plex Sans',
        Lato,
        Work Sans,
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    width: auto;
    height: 50px;
    align-self: center;
    white-space: normal;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    user-select: none;
    outline: none;
    text-align: center;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 0.25s;
    transition-property: background-color;
    transition-duration: 0.25s;
    -webkit-tap-highlight-color: transparent;
}

.btn-lg {
    min-width: 260px;
}

.btn[disabled] {
    cursor: initial;
}

.btn.btn-primary {
    background-color: #007b87;
    border: 1px solid #007b87;
    color: #ffffff;
}

.btn.btn-primary:hover:not([disabled]) {
    background-color: #00626b;
}

.btn.btn-primary:active,
.btn.btn-primary:focus {    
    background-color: #00626b;    
    outline: none;
    border: 1px solid #007b87;
}

.btn.btn-primary .btn-text {
    display: block;    
    color: #ffffff;
    font-family:
        canada-type-gibson,
        'IBM Plex Sans',
        Lato,
        Work Sans,
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
    font-weight: 600;
    letter-spacing: 0.03125rem;
    line-height: 1.5;
    transition: all 0.2s; 
}

.btn.btn-primary:active .btn-text {
    opacity: 0.5;
}

.btn.btn-primary.btn--loading .btn-text {
    // visibility: hidden;
     opacity: 0.5;
}

.btn.btn-primary.btn--loading {
    background-color: #00626b;
    border-color: #00626b;
}

.btn.btn-primary.btn--loading::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    right: 80%;
    bottom: 0;
    margin: auto;
    border: 3px solid #f2fbfc;
    border-top-color: #007b87;
    border-radius: 50%;
    animation: button-loading-spinner 0.8s ease infinite;
}

.btn.btn-primary.btn--loading::after {
    border: 3px solid #ffffff;
    border-top-color: #007b87;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }
    to {
        transform: rotate(1turn);
    }
}

.btn.btn-secondary {
    color: #007b87;
    background-color: transparent;
    border: 1px solid #007b87;
}

.btn.btn-secondary:hover {
    color: #00626b;
    background-color: #f2fbfc;
}

.btn.btn-secondary:active,
.btn.btn-secondary:focus {
    color: RGBA(0, 123, 135, 0.5);
    outline: 0;
    outline: none;
    border: 1px solid #007b87;
    background-color: #f2fbfc;
}

.btn.btn-link {
    color: #007b87;
    width: auto;
    padding: 12px 36px;
//    margin: 0 2rem;
    background: transparent;
    text-decoration: none;
    border-radius: 0;
    display: block;
}

.btn.btn-link:hover {
    color: #484848;
}

.btn.btn-link:active,
.btn.btn-link:focus {
    border-bottom: none;
    outline: none;
}

.title {
    font-family: 'canada-type-gibson', sans-serif;
    color: #333;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.2;
    width: 100%;
}

.form-title {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 24px;
    line-height: 1.2;
}

.title-medium {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 32px;
    line-height: 1.5;
}

.recaptcha {
    margin: 2rem 0 0 0;
    display: block;
}

/***
**** Applicaton Header Branding Overrides
***/


.nmu .app-header {
    border-bottom: 2px solid #133d8d;
    padding: 10px;
}

.nmu .app-header .logo {
    height: 60px;
    width: auto;
}

.nmu .app-header .navbar-nav .nav-item .nav-link {
    color: #133d8d;
}

.nmu .app-header .fa-phone {
    color: #133d8d;
}


/***
**** NMU Branding Overrides
****
***/


.nmu a {
    color: #133d8d;
}

.nmu a:hover {
    color: #484848;
    text-decoration: none;
    border-bottom: 1px solid #484848;
}

.nmu a:active {
    color: #133d8d;
}

.nmu .form-group.form-check input[type='checkbox']:checked + label::before {
    background-color: #133d8d;
    border: 1px solid #133d8d;
}

.nmu .app-container .form-control:focus {
    -webkit-box-shadow: 0 0 0 1px #133d8d;
    box-shadow: 0 0 0 1px #133d8d;
    border-color: #133d8d;
    outline: 0;
}

.nmu .app-container .form-control:focus {
    -webkit-box-shadow: 0 0 0 1px #0c559c;
    box-shadow: 0 0 0 1px #0c559c;
    border-color: #0c559c;
    outline: 0;
}

.nmu .app-container .form-icon {
    color: #133d8d;
}

.nmu .app-container .btn.btn-primary {
    background-color: #133d8d;
}

.nmu .app-container .btn.btn-primary:hover:not([disabled]) {
    background-color: #545454;
}

.nmu .app-container .btn.btn-secondary {
    color: #133d8d;
    background-color: transparent;
    border: 1px solid #133d8d;
}

.nmu .app-container .btn.btn-secondary:hover {
    color: #133d8d;
    background-color: #eaebec;
}

.nmu .app-container .toggle-password:hover {
    color: #133d8d;
}

.nmu .app-container .toggle-password:active {
    color: #133d8d;
}


/***
*** Application loading wait state indication
**/

.app-loading {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.app-loading .spinner {
    height: 200px;
    width: 200px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.app-loading .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #ddd;
}

.app-loading .logo {
    width: 100px;
    height: 100px;
    background: url('./assets/icons/intact/favicon-76.png') center center no-repeat;
}
.app-loading .logo.dewar {
    background: inherit;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
